:root {
  --main-color: #6b6b6b;
  --link-color: aliceblue;
}

a {
  color: var(--link-color);
}

.spinnerText {
  text-align: center;

}


.progressdashboard .MuiLinearProgress-barColorPrimary {
  background-color: var(--main-color) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
* {
  box-sizing: border-box;
}

.scheduletooltip {
  padding: 20px;
  margin-top: -45px;
}
@media only screen and (min-device-width: 900px) {
  .scheduletooltip {
    padding: 20px;
    margin-top: -40px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.floor-plan {
  outline: 0;
  touch-action: none;

  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.disable-touch-events {
  touch-action: none;
}

.disable-pointer-events {
  pointer-events: none;
}

html {
  --scrollbarBG: transparent;
  --thumbBG: var(--main-color);
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 4px;
  border: 1px solid var(--scrollbarBG);
}

.zone:focus {
  outline: none;
}
